<template>
  <div v-if="!loading" >
    <v-img
        v-if="entity.img"
        :height="!SM ? '334' : '200'"
        :src="entity.img"
        transition="xxx"
    />
    <v-sheet v-else class="d-flex justify-center"  :height="!SM ? '334' : '200'">
      <img
          :height="!SM ? '334' : '200'"
          width="auto"
          src="/library/img/support/updates.png"
      />
      <!--        -->
    </v-sheet>

    <v-sheet class="mx-auto pt-4" color="transparent" max-width="448" width="100%">

      <!-- Date, Reading time , socials -->
      <h5 class="wsDARKLIGHT font-weight-regular mb-4">
        {{ $t(`support.updates.types.${entity.type}`) }} · {{ PARSE_DATE(entity.date , false ,true).toLowerCase() }}
      </h5>
      <!-- Title -->
      <h3 style=" line-height: 1.2; "
          :style="`color : ${wsDARKER};`"
          class="mb-4">
        {{ entity.name }}
      </h3>

      <ws-text-viewer
          class="mt-5"
          :value="entity.content"
          font-size="16"
          is-public
          video-height="248"
      />


<!--        <v-sheet class="wsRoundedHalf py-4 px-4 mt-12">-->
<!--          <div class="d-flex justify-center">-->
<!--            <v-icon :color="wsATTENTION" class="mt-3">mdi-account-multiple</v-icon>-->
<!--          </div>-->
<!--          <h5 class="font-weight-regular px-4 text-center mt-3">-->
<!--            Якщо для вашої школи дане оновлення є актуальним та корисним, будь ласка відмітьте це. Таким чином ми ви допомагаєте нам корегувати плани оновлень, а значить - робити сервіс зручнішим для Вас-->
<!--          </h5>-->
<!--          <div class="d-flex justify-center">-->
<!--            <ws-button left min-width="160" icon="mdi-thumb-up-outline" class="my-3 mt-6" label="Це актуально" />-->
<!--          </div>-->

<!--        </v-sheet>-->


      <div style="height : 50px"></div>
    </v-sheet>
  </div>

  <!-- Loading -->
  <div v-else class="fill-height d-flex align-center justify-center">
    <div style="width: 100px">
      <v-progress-linear rounded indeterminate :color="wsACCENT" />
    </div>
  </div>


</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "updateViewSupport",
  props : {
    uuid : {
      type : String
    }
  },
  data() {
    return {
      entity : {},
      loading : false,
    }
  },
  methods : {
    ...mapActions('support', [
      'GET_UPDATE',
    ]),

    async initPage() {
      this.loading = true
      let result = await this.GET_UPDATE(this.uuid)
      this.loading = false
      if ( !result ) {
        return this.ERROR()
      }
      this.entity = result
    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>